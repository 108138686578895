import React, { useEffect, useState } from "react";
import Label from "../core/Label";
import Input from "../core/Input";
import DatePicker from "../core/DatePicker";
import AddressInput from "../core/AddressInput";
import { parseISO, set } from "date-fns";
import { trimTime } from "../../utils/time";
import { Button } from "../core/Button";
import { Checkbox } from "../core/Checkbox";
import CountrySelect from "../core/CountrySelect";
import UsaStateSelect from "../core/UsaStateSelect";
import IdentificationTypeSelect from "../core/IdentificationTypeSelect";
import { RadioGroup, RadioGroupItem } from "../core/RadioGroup";
import { useLocation } from "react-router-dom";

type IndividualFormContentProps = {
  value: any;
  onChange: (value: any) => void;
  onDelete?: (id: string) => void;
};

const IndividualFormContent = (props: IndividualFormContentProps) => {
  const location = useLocation();
  const [isBoiForm, setIsBoiForm] = useState(false);

  const { value, onChange, onDelete } = props || {};

  const handleDelete = () => {
    onDelete?.(value.id);
  };

  useEffect(() => {
    setIsBoiForm(location.pathname.includes("beneficial-owners"));
  }, []);

  return (
    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8">
      <div className="sm:col-span-4">
        <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
          FinCEN ID
        </h3>
        <Label>FinCEN ID Number</Label>
        <div>
          <Input
            id="fincenId"
            value={value.fincenId}
            onChange={(e) => onChange({ ...value, fincenId: e.target.value })}
            name="fincenId"
            type="text"
          />
          <p className="text-xs pb-2">
            If you do not have a FinCEN ID Number, please provide identifying
            document and personal information below
          </p>
        </div>
      </div>

      {!value.fincenId && (
        <>
          <div className="sm:col-span-4">
            <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
              Form of Identification
            </h3>
            <Label>Identification Document *</Label>
            <p className="text-xs pb-2">
              Only .jpg, .png, pdf files, 500kb max file size
            </p>
            <div className="mt-2">
              <Input
                id={`identificationDocument-${value.id}`}
                value={""}
                onChange={(e) => {
                  onChange({
                    ...value,
                    identificationDocument: e.currentTarget.files?.[0],
                    uploadChanged: true,
                  });
                }}
                name={`identificationDocument-${value.id}`}
                type="file"
                className="hidden"
              />
              <div className="pb-2">
                {value.identificationDocument?.filename ? (
                  <a
                    href={value.identificationDocument?.url}
                    target="_blank"
                    className="text-blue-500 hover:text-blue-800"
                  >
                    {value.identificationDocument.filename}
                  </a>
                ) : (
                  <>{value.identificationDocument?.name}</>
                )}
              </div>
              <label htmlFor={`identificationDocument-${value.id}`}>
                <div
                  className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium
                                        ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 
                                        focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50
                                        bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2"
                >
                  Add files
                </div>
              </label>
            </div>
          </div>
          <div className="sm:col-span-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-2">
                <div className="w-1/2">
                  <IdentificationTypeSelect
                    label="Identification Type"
                    value={value.identificationType}
                    handleOnChange={(idType) =>
                      onChange({ ...value, identificationType: idType })
                    }
                  />
                </div>
                <div className="w-1/2">
                  <Label>Identification Number *</Label>
                  <Input
                    id="identificationNumber"
                    value={value.identificationNumber}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        identificationNumber: e.target.value,
                      })
                    }
                    name="identificationNumber"
                    type="text"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-1/2">
                  <CountrySelect
                    label="Issuing Country/Jurisdiction *"
                    value={value.identificationIssuingJurisdiction}
                    handleOnChange={(country) =>
                      onChange({
                        ...value,
                        identificationIssuingJurisdiction: country,
                      })
                    }
                  />
                </div>
                {value.identificationIssuingJurisdiction === "US" && (
                  <div className="w-1/2">
                    <UsaStateSelect
                      label="Issuing State/Jurisdiction"
                      value={value.issuingState}
                      handleOnChange={(state) =>
                        onChange({ ...value, issuingState: state })
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <h3 className="text-lg font-semibold leading-9 tracking-tight text-gray-900 pb-2">
              Personally Identifiable Information
            </h3>
            {!isBoiForm && (
              <>
                <div className="flex flex-row mt-2.5">
                  <label
                    htmlFor="addressType"
                    className="text-sm font-medium text-gray-900"
                  >
                    Address Type *
                  </label>
                </div>
                <div className="flex flex-row items-center mb-4">
                  <RadioGroup
                    id="addressType"
                    onValueChange={(checked) =>
                      onChange({ ...value, addressType: checked })
                    }
                    value={value.addressType}
                    className="flex flex-row space-y-1 items-center"
                  >
                    <RadioGroupItem id="business" value="business" />
                    <Label htmlFor="true">Business Address</Label>
                    <RadioGroupItem id="residential" value="residential" />
                    <Label htmlFor="false">Residential Address</Label>
                  </RadioGroup>
                </div>
              </>
            )}

            {isBoiForm && (
              <div className="flex flex-row gap-2 items-center mt-2.5 mb-4">
                <Checkbox
                  id="isParentGuardianInfo"
                  checked={value.isParentGuardianInfo}
                  onCheckedChange={(checked) =>
                    onChange({ ...value, isParentGuardianInfo: checked })
                  }
                />
                <label
                  htmlFor="isParentGuardianInfo"
                  className="text-sm font-medium text-gray-900"
                >
                  Parent/Guardian Information instead of Minor Child
                </label>
              </div>
            )}
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2">
                <div>
                  <Label>First Name *</Label>
                  <Input
                    id="firstName"
                    value={value.firstName}
                    onChange={(e) =>
                      onChange({ ...value, firstName: e.target.value })
                    }
                    name="firstName"
                    type="text"
                  />
                </div>
                <div>
                  <Label>Middle Name</Label>
                  <Input
                    id="middleName"
                    value={value.middleName}
                    onChange={(e) =>
                      onChange({ ...value, middleName: e.target.value })
                    }
                    name="middleName"
                    type="text"
                  />
                </div>
                <div>
                  <Label>Last Name *</Label>
                  <Input
                    id="lastName"
                    value={value.lastName}
                    onChange={(e) =>
                      onChange({ ...value, lastName: e.target.value })
                    }
                    name="lastName"
                    type="text"
                  />
                </div>
                <div>
                  <Label>Suffix</Label>
                  <Input
                    id="suffix"
                    value={value.suffix}
                    onChange={(e) =>
                      onChange({ ...value, suffix: e.target.value })
                    }
                    name="suffix"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2">
                <div className="flex flex-col flex-1">
                  <Label>Date of Birth *</Label>
                  <DatePicker
                    value={parseISO(
                      trimTime(value.dateOfBirth || new Date().toISOString())
                    )}
                    onChange={(dateOfBirth) =>
                      onChange({
                        ...value,
                        dateOfBirth: dateOfBirth.toISOString(),
                      })
                    }
                  />
                </div>
                <div className="flex flex-col flex-2 mt-auto mb-0">
                  <div className="flex flex-row gap-2 items-center mt-2.5 mb-4">
                    <Checkbox
                      id="dateOfBirthUnknown"
                      checked={value.dateOfBirthUnknown}
                      onCheckedChange={(checked) =>
                        onChange({ ...value, dateOfBirthUnknown: checked })
                      }
                    />
                    <label
                      htmlFor="dateOfBirthUnknown"
                      className="text-sm font-medium text-gray-900"
                    >
                      Date of Birth Unknown
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:col-span-4">
            <Label>Address</Label>
            <AddressInput
              value={value.address || {}}
              onChange={(address) => onChange({ ...value, address })}
            />
          </div>

          <div className="sm:col-span-4">
            <Button variant="destructive" onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default IndividualFormContent;
